.slider{
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    overflow: hidden;
    padding: 80px 0;
    
    &--title{
        text-align: left;
        display: flex;
        width: auto;
        height: auto;

        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;

        padding: 0 50px 30px 50px;

    }

    &--container{
        height: auto;
        width: 140%;
        display: flex;
    }

    &--item-container{
        height: 400px;
        width: calc(25% - 20px);
        padding: 0 10px;
    }

    &--item{
        height: 100%;
        background-size: contain;
        border-radius: 15px;
        overflow: hidden;

        &--overlay {
            height: 100%;
            width: 100%;

            background:linear-gradient(179.94deg, rgba(223, 225, 229, 0) 0.06%, rgba(21, 22, 25, 0.1) 99.94%);
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        &--title {
            color: rgb(0, 0, 0);
            display: flex;
            backdrop-filter: blur(10px);
            background: rgba(255, 255, 255, 0.107);
            border:1px solid rgba(255, 255, 255, 0.147);
            text-align: left;
            padding: 15px 15px;
            width: 80%;
            margin: 0 0 10px 0;
            border-radius: 12px;
            height: auto;

            p{
                margin: 0;
            }

            span{
                display: flex;
                margin-left: auto;
                width: 20px;
                height: 10px;
                height: 100%;
                padding: 2px;
                filter: brightness(0%);
            }
        }
    }

    padding: 80px 0;
}

.slick-slide img {
    margin: 0 auto;
}