.information{
    padding: 80px 0;
    .container{
        justify-content: center;
        align-items: center;
    }

    &--text{
        padding: 40px;
        width: 660px;
        text-align: center;
        font-size: 22px;
        padding: 40px;
        color: #979CA8;
    }

     &--dropdown{
        padding: 40px;
        width: 660px;
        text-align: left;
        font-size: 22px;

        .information-block{
            width: 100% !important;
        }
    }
}