.Ontology{
    width: 100%;
    height: auto;
    background-color: #eff0f2;
    display: flex;
    flex-direction: column;
    padding: 80px 0;
}

.ontology-logo{
    width: 80%;
    margin: 0 auto;
}

.ontology--information{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 2;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
    padding: 0 0 20px 0;
}

.information-block{
    width: 600px;

    border-top: 1px solid #B0B4BD;
    padding: 10px 0 5px 0;


    font-size: 20px;
    font-weight: 400;
    text-align: left;

}

.information-block--title{
    display: flex;
    width: 100%;
    cursor: pointer;
}

.information-block--icon {
    margin-left: auto;
}

.information-block--icon img {
    transition: transform 0.2s ease; /* Smooth transition for rotation */
  }
  
.information-block--icon.rotate img {
    transform: rotate(180deg); /* Rotate the icon by 90 degrees */
}