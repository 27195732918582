@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.cover{
  width: 100%;
  height: auto;
  background: url("../assets/banner-visuels/DotBackground.png") center no-repeat;

  .container{
    padding: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 100%;

    .cover--left, .cover--right{
      flex: 1;
      height: 100vh;
    }

    .cover--left{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .cover--block{
        text-align: left;
        width: 560px;
        margin: 0 0 170px 170px;

        h1 {
            font-size: 72px;
            font-weight: 400;
            line-height: 80px;
            margin: 0 0 50px 0;
        }

        p
        {
              font-size: 18px;
              font-weight: 400;
              line-height: 24.6px;
        }
      }
    }

    .cover--right{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;


      .cover--circle{
        position: absolute;
        max-height: 800px;
        max-width: 800px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size:contain; 
        background: url("../assets/banner-visuels/Ring.png") center no-repeat;
        justify-content: center;



        .cover--item {
          position: absolute;
          width: 180px;
          font-size: 14px;
          height: auto;
          background: #fbfbfc14;
          border:1px solid #DFE1E5;
          border-radius: 8px;
          padding: 10px 0px;
          backdrop-filter: blur(5px);
        }

        .cover--item:nth-child(1) {
          animation: circular-motion 30s linear infinite;
          animation-delay: 0s;
        }
        
        .cover--item:nth-child(2) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -3.75s;
        }
        
        .cover--item:nth-child(3) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -7.5s;
        }
        
        .cover--item:nth-child(4) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -11.25s;
        }
        
        .cover--item:nth-child(5) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -15s;
        }
        
        .cover--item:nth-child(6) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -18.75s;
        }
        
        .cover--item:nth-child(7) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -22.5s;
        }
        
        .cover--item:nth-child(8) {
          animation: circular-motion 30s linear infinite;
          animation-delay: -26.25s;
        }
        


      

        @keyframes circular-motion {
          from {
            transform: rotate(0deg) translateX(150%) rotate(0deg);
          }
          to {
            transform: rotate(360deg) translateX(150%) rotate(-360deg);
          }
        }
      
      }
    }
  }
}


@media only screen and (min-width: 1122px) and (max-width: 1499px) {

  .cover{
    width: 100%;
    height: auto;
    background: url("../assets/banner-visuels/DotBackground.png") center no-repeat;
  
    .container{
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: row;
      max-width: 100%;
      overflow: hidden;
  
      .cover--left, .cover--right{
        flex: 1;
        height: 100vh;
      }
  
      .cover--left{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
  
        .cover--block{
          text-align: left;
          width: 560px;
          margin: 0 0 40px 40px;
  
          h1 {
              font-size: 72px;
              font-weight: 400;
              line-height: 80px;
              margin: 0 0 50px 0;
          }
  
          p
          {
                font-size: 18px;
                font-weight: 400;
                line-height: 24.6px;
          }
        }
      }
  
      .cover--right{
        display: flex;
        justify-content: center;
        align-items: center;


        .cover--circle{
          position: absolute;
          max-height: 800px;
          max-width: 800px;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size:contain; 
          background: url("../assets/banner-visuels/Ring.png") center no-repeat;
          background-size: contain;
  
  
  
          .cover--item {
            position: absolute;
            width: 140px;
            font-size: 10px;
            height: auto;
            background: #fbfbfc14;
            border:1px solid #DFE1E5;
            border-radius: 8px;
            padding: 10px 0px;
            backdrop-filter: blur(5px);
          }
  
          .cover--item:nth-child(1) {
            animation: circular-motion 30s linear infinite;
            animation-delay: 0s;
          }
          
          .cover--item:nth-child(2) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -3.75s;
          }
          
          .cover--item:nth-child(3) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -7.5s;
          }
          
          .cover--item:nth-child(4) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -11.25s;
          }
          
          .cover--item:nth-child(5) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -15s;
          }
          
          .cover--item:nth-child(6) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -18.75s;
          }
          
          .cover--item:nth-child(7) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -22.5s;
          }
          
          .cover--item:nth-child(8) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -26.25s;
          }
        }
      }
    }
  }
}



@media only screen and (min-width: 875px) and (max-width: 1121px) {

  .cover{
    width: 100%;
    height: auto;
    background: url("../assets/banner-visuels/DotBackground.png") center no-repeat;
  
    .container{
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: row;
      max-width: 100%;
  
      .cover--left, .cover--right{
        flex: 1;
        height: 100vh;
      }
  
      .cover--left{
        display: flex;
        justify-content: center;
        align-items: center;
  
        .cover--block{
          text-align: left;
          width: 100%;
          margin: 40px;
  
          h1 {
              font-size: 48px;
              font-weight: 400;
              line-height: 80px;
              margin: 0 0 15px 0;
          }
  
          p
          {
                font-size: 18px;
                font-weight: 400;
                line-height: 24.6px;
          }
        }
      }
  
      .cover--right{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;


        .cover--circle{
          position: absolute;
          max-height: 800px;
          max-width: 800px;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size:contain; 
          left: 50%;
          background: url("../assets/banner-visuels/Ring.png") center no-repeat;
          background-size: contain;

  
  
  
          .cover--item {
            position: absolute;
            width: 200px;
            font-size: 14px;
            height: auto;
            background: #fbfbfc14;
            border:1px solid #DFE1E5;
            border-radius: 8px;
            padding: 10px 0px;
            backdrop-filter: blur(5px);
          }
  
          .cover--item:nth-child(1) {
            animation: circular-motion 30s linear infinite;
            animation-delay: 0s;
          }
          
          .cover--item:nth-child(2) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -3.75s;
          }
          
          .cover--item:nth-child(3) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -7.5s;
          }
          
          .cover--item:nth-child(4) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -11.25s;
          }
          
          .cover--item:nth-child(5) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -15s;
          }
          
          .cover--item:nth-child(6) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -18.75s;
          }
          
          .cover--item:nth-child(7) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -22.5s;
          }
          
          .cover--item:nth-child(8) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -26.25s;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 874px) {

  .cover{
    width: 100%;
    height: auto;
    background: url("../assets/banner-visuels/DotBackground.png") center;
  
    .container{
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 100%;
  
      .cover--left, .cover--right{
        flex: auto;
        height: 100vh !important;
        width: 100%;
      }
  
      .cover--left{
        display: flex;
        justify-content: center;
        align-items: center;
  
        .cover--block{
          text-align: left;
          width: 100%;
          margin: 40px;
  
          h1 {
              font-size: 48px;
              font-weight: 400;
              line-height: 80px;
              margin: 0 0 15px 0;
          }
  
          p
          {
                font-size: 18px;
                font-weight: 400;
                line-height: 24.6px;
          }
        }
      }
  
      .cover--right{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;



        .cover--circle{
          position: absolute;
          max-height: 800px;
          max-width: 800px;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size:contain; 
          left: 50%;
          background: url("../assets/banner-visuels/Ring.png") center no-repeat;
          background-size: contain;
  
  
  
          .cover--item {
            position: absolute;
            width: 150px;
            font-size: 14px;
            height: auto;
            background: #fbfbfc14;
            border:1px solid #DFE1E5;
            border-radius: 8px;
            padding: 10px 0px;
            backdrop-filter: blur(5px);
          }
  
          .cover--item:nth-child(1) {
            animation: circular-motion 30s linear infinite;
            animation-delay: 0s;
          }
          
          .cover--item:nth-child(2) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -3.75s;
          }
          
          .cover--item:nth-child(3) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -7.5s;
          }
          
          .cover--item:nth-child(4) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -11.25s;
          }
          
          .cover--item:nth-child(5) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -15s;
          }
          
          .cover--item:nth-child(6) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -18.75s;
          }
          
          .cover--item:nth-child(7) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -22.5s;
          }
          
          .cover--item:nth-child(8) {
            animation: circular-motion 30s linear infinite;
            animation-delay: -26.25s;
          }
        }
      }
    }
  }
}