@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding: 0;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    scrollbar-width: none;  
}

.section {
    height: 100%;
    width: 100%;
}

.container{
    display: flex;
    margin: 0 auto;
    max-width: 1700px;
    height: 100%;
    padding: 0 20px;
}

.footer-image{
    background-image: url("../assets/banner-visuels/World\ Map.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    max-width: 700px;
    padding: 60px 0 !important;
}

@import "slider.scss";
@import "information.scss";