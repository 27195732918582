@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.banner-container {
    overflow: hidden;
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
}


.banner-image{
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    min-height: auto;
}

.banner-image img{
    width: 100%;
    height: max-content;
}

.banner-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: left;
    width:648px;
    height:330px;
    top: 333px;
    left: 352px;
    gap: 32px;

}

.banner-text span{
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: left;
    color: #0070FF;
}

.banner-text h2{
    font-family: 'Work Sans', sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    text-align: left;
    margin: 0;
}

.banner-text p{
    font-family: 'Work Sans', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    color: #979CA8;
    margin: 0;
}

.left-text{
    width: 50%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
}


.right-text{
    width: 50%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
}


.banner-border{
    width: 80%;
    background: #99D3FF;
    height: 1px;
    margin:0 auto
}

.left-content {
    flex-direction: row-reverse;
}

.right-content {
    flex-direction: row;
}


.banner-container-time-top-left{
    position: absolute;
    top: 30px;
    left: 12%;
}

.banner-container-time-bottom-left{
    position: absolute;
    bottom: 30px;
    left: 12%;
}

.banner-container-time-top-right{
    position: absolute;
    top: 30px;
    right: 12%;
}

.banner-container-time-bottom-right{
    position: absolute;
    bottom: 30px;
    right: 12%;
}

.TimeClass span{
    font-family: 'Work Sans', sans-serif;
    color: #66B6FF;
    font-size: 17px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
    
}

.TimeClass p{
    font-family: 'Work Sans', sans-serif;
    color: #979CA8;
    font-size: 42px;
    font-weight: 200;
    line-height: 40.5px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0;
}



@media (min-width: 751px) and (max-width: 1300px) {
    .right-text {
      .banner-text{

        h2 {
          font-size: 30px !important;
          line-height: 40px;
        }

        p {
          font-size: 18px;
          line-height: 34px;
        }
        
        gap: 14px;
        padding:0 0 0 20px;

      }
    }

    .left-text {
      .banner-text{

        h2 {
          font-size: 30px !important;
          line-height: 40px;
        }

        p {
          font-size: 18px;
          line-height: 34px;

        }

        gap: 14px;
        padding:0 20px 0 0;
      }
    }

    .banner-container-time-bottom-left{
      left: 5% !important;
    }

    .banner-container-time-bottom-right{
      right: 5% !important;
    }

    .banner-border{
      width: 95% !important;
    }
  }

  @media (max-width: 750px) {
    .banner-container{
      flex-direction: column !important;
        padding: 100px 20px;
    }
    .right-text {
      width: 100% !important;

      .banner-text{
        span{
          text-align: left !important;
        }

        h2 {
          font-size: 20px !important;
          line-height: 40px;
          text-align: left !important;
        }

        p {
          font-size: 14px;
          line-height: 34px;
          text-align: left !important;
        }
        height: auto;
        gap: 14px;
      }
    }

    .left-text {
      width: 100% !important;

      .banner-text{

        span{
          text-align: left !important;
        }

        h2 {
          font-size: 20px !important;
          line-height: 40px;
          text-align: left !important;
        }

        p {
          font-size: 14px;
          line-height: 34px;
          text-align: left !important;

        }
        height: auto;
        gap: 14px;
        padding: 30px 0 0 20px;
      }
    }

    .banner-container-time-bottom-left{
      top: 50px !important;
      right: 5px !important;
      left: unset !important;
      width: 120px;
      height: 80px;
      justify-content: center;
      align-items: center;
    }

    .banner-container-time-bottom-right{
      top: 50px !important;
      right: 5px !important;
      left: none !important;
      width: 120px;
      height: 80px;
      justify-content: center;
      align-items: center;
    }

    .banner-border{
      width: 95% !important;
    }

    .banner-border{
        display: none;
    }
    .banner-image {
        flex-direction: column;
    }

    .banner-border-mobile {
        background: #99D3FF;
        height: 1px;
        margin: 0 auto;
        width: 100%;
        margin: 0 0 10px 0
    }
  }