.header{
    width: 100%;
    height: auto;
    position: absolute;
    padding: 80px 0 0 0;
}


.header .container{
    max-width: 1750px;
    height: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.container{
    display: flex;
    margin: 0 auto;
    max-width: 1750px;
    height: 100%;
    padding: 0 20px;
}

.header--menu{
    margin-left: auto;
}